<template>
  <HXContentCard :defaultToBack="true">
    <template #title>
      <h4 class="text-white-50">Cuentas de cobro y soportes</h4>
      <small>
        <strong>{{ profesional }}</strong>
      </small>
    </template>
    <!-- modal auditar archivos -->
    <b-modal
      id="auditarArchivos"
      title="Soportes de cuentas de cobro"
      size="lg"
      hide-footer
      class="modal-altura-personalizada"
    >
      <div>
        <div v-for="(item, index) in itemsFiles" :key="index">
          <div class="d-flex my-2 align-items-center bg-dark pointer">
            <div style="width: 100%" @click="toggle(index)" class="bg-dark p-3">
              <div class="d-flex align-items-center">
                <i
                  class="mr-2"
                  :class="[
                    'fas',
                    isActive(index) ? 'fa-chevron-down' : 'fa-chevron-right',
                  ]"
                ></i>
                <span>{{ item.title }}</span>
              </div>
            </div>
            <div class="ml-auto d-flex align-items-center">
              <!-- soporte modificado -->
              <div class="file-modify-badge" v-if="item.estado == -1">
                <i class="fas fa-file-arrow-down fa-2x p-2"></i>
              </div>

              <!-- acciones -->
              <vs-button
                v-if="item.url"
                v-b-tooltip.hover
                title="Visualizar"
                success
                icon
                @click="gotoFile(item.url)"
              >
                <i class="fas fa-eye me-2"></i>
              </vs-button>
              <vs-button
                v-if="item.url"
                :flat="[0, -1, null].includes(item.estado)"
                v-b-tooltip.hover
                title="Aprobar"
                primary
                icon
                @click="saveNewStateGestionFile(item.id, true)"
              >
                <i class="fa-solid fa-thumbs-up"></i>
              </vs-button>
              <vs-button
                v-if="item.url"
                :flat="[1, -1, null].includes(item.estado)"
                v-b-tooltip.hover
                title="Rechazar"
                danger
                icon
                @click="saveNewStateGestionFile(item.id, false)"
              >
                <i class="fa-solid fa-thumbs-down"></i>
              </vs-button>
            </div>
          </div>

          <transition
            name="fade"
            @before-enter="beforeEnter"
            @enter="enter"
            @leave="leave"
          >
            <div v-if="isActive(index)">
              <div class="d-flex justify-content-end" v-if="item.url">
                <span class="mx-2">
                  <i class="fas fa-cloud-arrow-up"></i>
                  Última subida:
                  {{ item.fechaSubida | moment("from", "now") }}
                </span>
                <span class="mx-2" v-if="item.fechaEstado">
                  <i class="fas fa-circle-check"></i>
                  Última auditoría:
                  {{ item.fechaEstado | moment("from", "now") }}
                </span>
              </div>
              <ComentariosCuentaCobro
                :dataSendCommentFile="buildObjectCommentFile(item.id)"
                :commentsFiles="item.comments"
                class="div-comentario-v2"
                v-if="item.url"
              />
              <p v-else class="text-center border rounded p-3">
                <i class="fas fa-file-circle-exclamation fa-2x"></i>
                <br />
                No se ha subido archivo.
              </p>
            </div>
          </transition>
        </div>
      </div>
    </b-modal>
    <div class="px-5 my-4">
      <CardTable :data="dataCards" />
    </div>
    <div class="row">
      <div class="col-12 col-lg-4 px-5">
        <label for="filterDate" class="form-label"
          ><i class="fas fa-calendar-days"></i> Filtrar por periodo:</label
        >
        <input
          id="date"
          type="month"
          class="form-control"
          v-model="currentFilterDate"
          @input="handleFilterDateInput"
          required
        />
      </div>
    </div>
    <div class="table-responsive p-4">
      <table
        id="datatable_archivos"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Periodo</th>
            <th>Fecha inicio</th>
            <th>Fecha fin</th>
            <th>Estado</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listFilesByPeriodos" :key="index">
            <td>
              <strong>{{ index + 1 }}</strong>
            </td>
            <td>{{ $moment(item.Periodo, "YYYY-MM").format("MMMM, YYYY") }}</td>

            <td>{{ $moment(item.FechaInicio).format("D [de] MMMM") }}</td>
            <td>{{ $moment(item.FechaFin).format("D [de] MMMM") }}</td>

            <td
              class="mt-3"
              :class="
                item.Estado == 1 ? 'badge badge-success' : 'badge badge-danger'
              "
            >
              {{ item.Estado == 1 ? "Completado" : "Incompleto" }}
            </td>
            <td style="width: 150px !important">
              <vs-button
                icon
                class="mx-auto"
                v-b-tooltip.hover
                title="Auditar archivos"
                @click="openAuditarModal(item.Archivos)"
              >
                <i class="fas fa-file-circle-check"></i>
              </vs-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </HXContentCard>
</template>

<script>
import CardTable from "../../../components/cardTable/CardTable.vue";

import ComentariosCuentaCobro from "@/components/ComentariosCuentaCobro/ComentariosCuentaCobro.vue";
import { core } from "../../../config/pluginInit";
const Swal = require("sweetalert2");

export default {
  components: {
    ComentariosCuentaCobro,
    CardTable,
  },
  data() {
    return {
      currentFilterDate: null,
      dataCards: [
        {
          title: "Incompleto",
          value: () =>
            this.listFilesByPeriodos.filter((item) => item.Estado != 1).length,
          iconName: "ban",
          color: "rgb(242, 19, 67)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("En proceso").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Completado",
          value: () =>
            this.listFilesByPeriodos.filter((item) => item.Estado == 1).length,
          iconName: "circle-check",
          color: "rgb(23, 167, 108)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Finalizado").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
      activeIndexes: [], // Array para mantener múltiples índices activos
      itemsFiles: [
        {
          id: "",
          title: "Cuenta de cobro",
          name: "_Cuenta_de_cobro",
          url: "",
          estado: null,
          fechaEstado: "",
          fechaSubida: "",
          comments: [],
        },
        {
          id: "",
          title: "Declaración juramentada",
          name: "_Declaracion_juramentada",
          url: "",
          estado: null,
          fechaEstado: "",
          fechaSubida: "",
          comments: [],
        },
        {
          id: "",
          title: "Planilla de seguridad social",
          name: "_Planilla_de_seguridad_social",
          url: "",
          estado: null,
          fechaEstado: "",
          fechaSubida: "",
          comments: [],
        },
        {
          id: "",
          title: "Póliza",
          name: "_Poliza",
          url: "",
          estado: null,
          fechaEstado: "",
          fechaSubida: "",
          comments: [],
        },
        {
          id: "",
          title: "Recibo de pago de la póliza",
          name: "_Recibo_de_pago_de_la_poliza",
          url: "",
          estado: null,
          fechaEstado: "",
          fechaSubida: "",
          comments: [],
        },
      ],
      listFilesByPeriodos: [],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.personaAct = JSON.parse(localStorage.getItem("setPersonaAct"));
    console.log(this.personaAct);

    core.index();
    await this.getListFilesByPeriodos();
    this.dataTableInstance = window.$("#datatable_archivos").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  computed: {
    personaId() {
      return this.$route.params.personaId;
    },
    profesional() {
      return this.$route.params.profesional;
    },
  },
  methods: {
    showToast(type, message) {
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    buildObjectCommentFile(archivoId) {
      console.log(this.personaAct);

      return {
        personaId: this.personaAct.id,
        archivoId: archivoId,
      };
    },
    async saveNewStateGestionFile(archivoId, newState) {
      try {
        this.$isLoading(true);

        let currentFile = this.itemsFiles.filter(
          (item) => item.id === archivoId
        )[0];

        console.log(currentFile);

        let res;

        let data = {
          auditorId: this.personaAct.id,
          archivoId: archivoId,
          estado: newState ? 1 : 0,
          fechaEstado: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
        };

        console.log(data);

        if (currentFile.estado === null) {
          console.log("ok");
          res = await this.$store.dispatch("hl_post", {
            path: "CDC/AddGestion",
            data,
          });
        } else {
          console.log("ok");
          res = await this.$store.dispatch("hl_put", {
            path: "CDC/UpdateEstadoGestion/" + archivoId,
            data,
          });
        }

        // actualizar datos locales
        currentFile.estado = newState ? 1 : 0;
        currentFile.fechaEstado = data.fechaEstado;

        // si todos los archivos están aprobados, cambiar el estado del periodo
        let allFilesApproved = this.itemsFiles.every(
          (item) => item.estado === 1
        );

        console.log(allFilesApproved);

        let periodo = this.listFilesByPeriodos.find((item) =>
          item.Archivos.some((archivo) => archivo.ArchivoId === archivoId)
        );
        console.log(periodo);

        let index = this.listFilesByPeriodos.indexOf(periodo);

        console.log(this.listFilesByPeriodos);
        this.listFilesByPeriodos[index]?.Archivos.forEach((archivo) => {
          if (archivo.ArchivoId === archivoId) {
            archivo.EstadoGestion = newState ? 1 : 0;
            archivo.FechaEstado = data.fechaEstado;
          }
        });

        this.listFilesByPeriodos[index].Estado = allFilesApproved ? 1 : 0;

        console.log(res);
        if (res.id) {
          // Swal.fire(
          //   "Listo!",
          //   "Se ha cambiado el estado correctamente.",
          //   "success"
          // );
          this.showToast("success", "Estado actualizado con éxito!");
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.error("Error al guardar cambios:", error);
        Swal.fire("Error!", "No se pudieron guardar los cambios.", "error");
      }
    },
    gotoFile(url) {
      const previewerUrl = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(
        url
      )}`;

      window.open(previewerUrl);
    },

    resetItems() {
      // Resetear itemsFiles para evitar datos acumulados
      this.itemsFiles = [
        {
          id: "",
          title: "Cuenta de cobro",
          name: "_Cuenta_de_cobro",
          url: "",
          estado: null,
          fechaEstado: "",
          fechaSubida: "",
          comments: [],
        },
        {
          id: "",
          title: "Declaración juramentada",
          name: "_Declaracion_juramentada",
          url: "",
          estado: null,
          fechaEstado: "",
          fechaSubida: "",
          comments: [],
        },
        {
          id: "",
          title: "Planilla de seguridad social",
          name: "_Planilla_de_seguridad_social",
          url: "",
          estado: null,
          fechaEstado: "",
          fechaSubida: "",
          comments: [],
        },
        {
          id: "",
          title: "Póliza",
          name: "_Poliza",
          url: "",
          estado: null,
          fechaEstado: "",
          fechaSubida: "",
          comments: [],
        },
        {
          id: "",
          title: "Recibo de pago de la póliza",
          name: "_Recibo_de_pago_de_la_poliza",
          url: "",
          estado: null,
          fechaEstado: "",
          fechaSubida: "",
          comments: [],
        },
      ];
    },
    openAuditarModal(archivos) {
      console.log(archivos);

      this.resetItems();

      archivos?.forEach((archivo) => {
        const item = this.itemsFiles.find(
          (item) => item.name === archivo.NombreArchivo.split(".")[0]
        );
        if (item) {
          item.id = archivo.ArchivoId;
          item.url = archivo.UrlArchivo;
          item.fechaSubida = archivo.FechaSubida;

          if (archivo.EstadoGestion != undefined) {
            item.estado = archivo.EstadoGestion;
            item.fechaEstado = archivo.FechaEstado;
          }

          if (archivo.Observaciones != undefined) {
            item.comments = archivo.Observaciones;
          }
        }
      });

      console.log(this.itemsFiles);

      this.$bvModal.show("auditarArchivos");
    },
    handleFilterDateInput() {
      if (this.currentFilterDate) {
        let formattedDate = this.$moment(
          this.currentFilterDate,
          "YYYY-MM"
        ).format("MMMM, YYYY");
        this.dataTableInstance.search(formattedDate).draw();
      } else {
        this.dataTableInstance.search("").draw();
      }
    },
    toggle(index) {
      // Si el índice ya está activo, lo eliminamos; si no, lo agregamos
      const idx = this.activeIndexes.indexOf(index);
      if (idx === -1) {
        this.activeIndexes.push(index);
      } else {
        this.activeIndexes.splice(idx, 1);
      }
    },
    isActive(index) {
      return this.activeIndexes.includes(index); // Verifica si el índice está en el array de activos
    },
    async getListFilesByPeriodos() {
      const query = `
WITH ArchivosPorPeriodo AS (
    -- Obtener los archivos de cada periodo por PersonaId
    SELECT
        f.Anio,
        f.Mes,
        f.FechaInicio,
        f.FechaFin,
        a.Id AS ArchivoId,
        a.PersonaId,
        a.NombreArchivo,
        a.UrlArchivo,
        a.FechaSubida,
        g.Estado AS EstadoGestion
    FROM
        dbo.CDCFechas f
    JOIN
        dbo.CDCArchivos a ON YEAR(a.FechaSubida) = f.Anio AND MONTH(a.FechaSubida) = f.Mes
    LEFT JOIN
        dbo.CDCGestion g ON a.Id = g.ArchivoId
    WHERE
        a.PersonaId = @personaId
        AND f.EmpresaId = @empresaId
),
ArchivosValidos AS (
    -- Verificar si existen exactamente 5 archivos aprobados por PersonaId y periodo
    SELECT
        Anio,
        Mes,
        MIN(FechaInicio) AS FechaInicio, -- Asegurar una única fila por período
        MIN(FechaFin) AS FechaFin, -- Asegurar una única fila por período
        COUNT(*) AS CantidadArchivosAprobados
    FROM
        ArchivosPorPeriodo
    WHERE
        EstadoGestion = 1 -- Solo archivos aprobados
    GROUP BY
        Anio,
        Mes
)
SELECT
    CAST(f.Anio AS VARCHAR) + '-' + RIGHT('00' + CAST(f.Mes AS VARCHAR), 2) AS Periodo,
    f.FechaInicio,
    f.FechaFin,
    CASE
        WHEN v.CantidadArchivosAprobados = 5 THEN 1
        ELSE 0
    END AS Estado,
    (SELECT
        a.Id AS ArchivoId,
        a.PersonaId,
        a.NombreArchivo,
        a.UrlArchivo,
        a.FechaSubida,
        g.Estado AS EstadoGestion,
        g.FechaEstado AS FechaEstado,
        (SELECT
            o.Id AS ObservacionId,
            o.PersonaId,
            o.Observacion,
            o.FechaObservacion
        FROM dbo.CDCObservaciones o
        WHERE o.ArchivoId = a.Id
        FOR JSON PATH) AS Observaciones
    FROM
        dbo.CDCArchivos a
    LEFT JOIN
        dbo.CDCGestion g ON a.Id = g.ArchivoId
    WHERE
        a.PersonaId = @personaId
        AND YEAR(a.FechaSubida) = f.Anio
        AND MONTH(a.FechaSubida) = f.Mes
    FOR JSON PATH) AS Archivos
FROM
    (SELECT DISTINCT Anio, Mes, FechaInicio, FechaFin, EmpresaId FROM dbo.CDCFechas) f
LEFT JOIN
    ArchivosValidos v ON f.Anio = v.Anio AND f.Mes = v.Mes
WHERE
    f.EmpresaId = @empresaId
ORDER BY
    f.Anio DESC, f.Mes DESC;

        `;
      try {
        const response = await this.$store.getters.fetchPost({
          path: `DynamicQuery/`,
          data: {
            query,
            parameters: {
              personaId: this.personaId,
              empresaId: this.personaAct.empresa.id,
            },
          },
        });
        if (response.ok) {
          const allData = await response.json();
          if (allData.data.length > 0) {
            allData.data.forEach((item) => {
              item.Archivos = JSON.parse(item.Archivos);
            });
            this.listFilesByPeriodos = allData.data;
          }
          console.log(allData.data);
        } else {
          console.error("Error en la respuesta del servidor", response);
        }
      } catch (error) {
        console.error("Error en getProfesionales:", error);
      }
    },
  },
};
</script>

<style scoped>
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.div-comentario {
  width: 250px;
}
.div-comentario-v2 {
  width: auto;
}
/* .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--warning);
  border-radius: 5px;
  padding: 20px;
} */
.accordion-title {
  background-color: var(--gray-100);
  padding: 10px;
  cursor: pointer;
  border: 1px solid var(--gray-100);
  margin: 5px 0;
}

.accordion-content {
  padding: 10px;
  border: 1px solid var(--gray-100);
  background-color: var(--gray-100);
}
.card-header {
  cursor: pointer;
}
.accordion-item {
  margin-top: 10px;
}
.icon-container i {
  font-size: 12px;
  /* color: rgb(253, 101, 0); */
  animation: pulse 0.8s infinite ease-in-out;
}

/* Animación de pulso */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
/* Ajuste de altura personalizada para el modal */
.modal-altura-personalizada .modal-dialog {
  max-height: 80vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.modal-altura-personalizada .modal-content {
  overflow-y: auto;
}

/* Estilo para lista de comentarios */
.comentarios-cuenta-cobro .list-group-item {
  padding: 15px;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}

.comentarios-cuenta-cobro .list-group-item:hover {
  background-color: #e8f0fe;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Detalle flotante */
.detalle-flotante {
  /* background: white; */
  /* border: 1px solid #ddd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); */
  border-radius: 5px;
  padding: 10px;
  width: auto;
  z-index: 10;
  top: 80%;
  left: 65%;
  transform: translateY(10px);
}
.notification {
  font-size: 18px; /* Tamaño del icono ajustado a 18px */
  color: #ffffff; /* Color del icono */
  animation: shake 1s ease-in-out infinite;
  transform-origin: top center; /* El punto de rotación ahora es la parte superior */
}

@keyframes shake {
  0% {
    transform: rotate(0deg) translateX(0);
  }
  20% {
    transform: rotate(10deg) translateX(5px);
  }
  40% {
    transform: rotate(-10deg) translateX(-5px);
  }
  60% {
    transform: rotate(5deg) translateX(3px);
  }
  80% {
    transform: rotate(-5deg) translateX(-3px);
  }
  100% {
    transform: rotate(0deg) translateX(0);
  }
}
</style>
